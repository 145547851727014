<template>
  <el-main class="simple1-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        循環30．成就女神
      </div>
      <p>2024年3月</p>
      <div class="image-container">
        <img :src="image" class="image" alt="健身房數位轉型 拓展女性健身市場">
        <p class="image_desc">健身房數位轉型 拓展女性健身市場</p>
        <p class="image_desc">圖片為CURVES健身房主視覺</p>
        <p class="image_desc">(圖片提供：右騰實業)</p>
      </div>
      <div class="content-section">
        <div class="section-h3">實證目標</div>
        <div class="content-desc">
          根據教育部體育署資料顯示，在2022年規律運動人口首次躍至34%，其中女性運動頻率更是令人矚目，超過九成以上每週至少運動一次，甚至高達58.4%女性每週運動三次以上。
        </div>
        <div class="content-desc">
          因看重女性未來健身市場的趨勢，本實證活動TA鎖定30-65歲女性，希望透過該活動能夠加強促進女性會員規律運動，並落實數據應用。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據貢獻活動</div>
        <div class="content-desc">
          右騰實業引進宇康醫電數位化健身系統，針對可爾姿女性健身房會員辦理
        </div>
        <div class="content-desc">「循環30．成就女神」，於112年9月3日至10月28日，共完成累計上傳5萬多筆運動生理數據，其中包含次數、心率、消耗卡路里等運動生理數據，完整數據留存數位健身系統，並已與運動數據公益平台串接。</div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用模式</div>
        <div class="content-desc">
          將Curves環狀運動與運動科技結合，導入數位化設備，蒐集數據後分析結果針對設計女性客製化運動課程。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用效益</div>
        <div class="content-desc">
          促使會員進行規律運動的同時提升留客率，提升健身數位加值服務體驗，拓展女性健身市場個人化運動服務商模。
        </div>
      </div>
    </section>

  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'UseCase11201',
  components: {
    MainContain
  },
  data () {
    return {
      image: require('@/assets/images/sample/112-01.jpg')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.simple1-container{
  .inner-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .image-container{
    padding: 1.5rem 0;
    .image {
      width: 80%;
      display: block;
      margin: 0 auto;
      }
    .image_desc{
      text-align: center;
    }
  }
  
  .content-section{
    
    .content-desc{
      line-height: 1.6;
    }
  }
}

</style>
